/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Apartmán k pronájmu v Bulharsku"}>
        <SiteHeader set="ly5dmp6gibc" currentLanguage={1} />

        <Column className="pb--20 pt--20" name={"[[UNIsecname1]]-0"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" animS={"3"} style={{"paddingLeft":0}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="pb--40 pt--40" style={{"maxWidth":780}}>
              
              <Title className="title-box title-box--center fs--72" content={"<font color=\"#f1f1f8\">Apartman in the apartment house RUSALKA</font><font color=\"#f1f1f8\"> </font>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"jomvvsgd3pj"} style={{"backgroundColor":"#0a2ec5"}} parallax={false}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--1 flex--center" anim={"2"} animS={"8"} columns={"1"}>
            
            <ColumnWrapper className="pb--40 pt--40" style={{"maxWidth":1300}}>
              
              <Text className="text-box text-box--center" content={"Featuring a seasonal outdoor pool and free WiFi, Rusalka Apart-Residence is located in Obzor. Centralen Obzor Beach is 200 metres away and Obzor North Beach\n900 metres away.\n<br>Boasting 2 balconies with city and sea views, the apartment features air conditioning, a flat-screen cable TV and a fully equipped kitchen. A fridge, microwave, stovetop, coffee machine and kettle are also provided. <br>Action Aquapark is 33 km from Rusalka Apart-Residence. Burgas Airport is 62 km from Rusalka Apart-Residence.<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-n8mmrj pb--80 pt--80" name={"[[UNIsectionname3]]"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"[[UNIsecname7]]"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: var(--color-dominant);\">Check in a check out<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Check-in is after 2 pm on the day of arrival and the apartment must be vacated by 11 am on the day of departure. A deposit of 2 nights is payable in advance and the balance is payable on arrival by card, bank transfer or cash."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: var(--color-dominant);\">Price list<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Prices for renting the whole apartment: in July and August 130 EURO/night, June and September 90 Euro/night, May and October 60 Euro/night, other months 45 Euro/night. 10% discount for rentals of 2 weeks or more (except July and August)."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<font color=\"#f4f5f7\">Length of stay</font>"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"The minimum stay is 3 days. Outside of July and August, it is exceptionally possible to use only the living room and one bedroom with bathroom at a discount by arrangement."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}